import React from 'react';

import { Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import Member from 'src/components/member/member';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const MemberPage = () => {
  return (
    <Layout>
      <SEO title="Member" />
      <Router basepath="/memberships/member">
        <MemberRoute path="/:memberId" />
      </Router>
    </Layout>
  );
};

const MemberRoute: React.FC<{ memberId?: string }> = ({ memberId }) => {
  return (
    <PrivateRoute>
      <Member memberId={memberId} />
    </PrivateRoute>
  );
};

export default MemberPage;
